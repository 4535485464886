import React, { useContext } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../contexts/SettingsContext';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(2),
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function TimeRangeSelect() {
    const classes = useStyles();
    const { selectedTimeRange, handleTimeRangeSelectChange } = useContext(SettingsContext);

    return (
        <form className={classes.root} autoComplete="off">
            <RadioGroup aria-label="position" name="position" value={selectedTimeRange} onChange={handleTimeRangeSelectChange} row>
                <FormControlLabel value="24HOUR" control={<Radio color="primary" />} label="24H" labelPlacement="bottom" />
                <FormControlLabel value="DAY" control={<Radio color="primary" />} label="DAY" labelPlacement="bottom" />
                <FormControlLabel value="HOUR" control={<Radio color="primary" />} label="HOUR" labelPlacement="bottom" />
            </RadioGroup>
        </form>
    );
}
