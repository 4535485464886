import React from 'react';
import { Typography } from '@material-ui/core';
import Clock from 'react-live-clock';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2),
    },
}));

const CryptoClock = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="overline">
                <Clock className="time" format={'h:mm:ss A'} ticking={true} />
                {' - '}
            </Typography>
            <Typography variant="overline">
                <Clock className="date" format={'dddd, MMMM Mo, YYYY'} ticking={true} />
            </Typography>
        </div>
    );
};

export default CryptoClock;
