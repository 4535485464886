import React, { Fragment, useContext } from 'react';
import { InputLabel, MenuItem, FormControl, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../contexts/SettingsContext';
import mainConfig from '../config/mainConfig';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        padding: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    errorMessage: {
        color: '#cc0000',
    },
    headline: {
        padding: theme.spacing(1),
        color: '#ffffff',
        background: '#021515',
        lineHeight: 'normal',
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    label: {
        top: 'initial',
        left: 'initial',
    },
    select: {
        //background: '#5a7170',
        // color: '#ffffff',
    },
}));

export default function CurrencySelect() {
    const classes = useStyles();
    const cryptoCurrencies = mainConfig.availableCryptoCurrencys;
    const fiatCurrencies = mainConfig.availableFiatCurrencys;

    const { selectedCurrencyFrom, handleCurrencyFromChange, selectedCurrencyTo, handleCurrencyToChange, errorMessage } = useContext(SettingsContext);

    const inputLabelA = React.useRef(null);
    const inputLabelB = React.useRef(null);
    const [labelWidthA, setLabelWidthA] = React.useState(0);
    const [labelWidthB, setLabelWidthB] = React.useState(0);

    React.useEffect(() => {
        setLabelWidthA(inputLabelA.current.offsetWidth);
        setLabelWidthB(inputLabelB.current.offsetWidth);
    }, []);

    return (
        <Fragment>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabelA} id="currency-label-from" className={classes.label} aria-label="select a currency - currency from">
                    CURRENCY FROM
                </InputLabel>
                <Select labelId="currency-label-from" id="currency-from" labelWidth={labelWidthA} value={selectedCurrencyFrom} onChange={handleCurrencyFromChange} name="from-ident" inputProps={{ name: 'currencyFrom', id: 'currency-label-from' }}>
                    <Typography component="h3" variant="overline" className={classes.headline} aria-label="group of fiat currencies">
                        Fiat
                    </Typography>
                    {fiatCurrencies.map(item => {
                        return (
                            item !== selectedCurrencyTo && (
                                <MenuItem key={`a${item}`} value={item} className={classes.select} aria-label={item}>
                                    {item}
                                </MenuItem>
                            )
                        );
                    })}

                    <Typography component="h3" variant="overline" className={classes.headline} aria-label="group of crypto currencies">
                        Crypto
                    </Typography>
                    {cryptoCurrencies.map(item => {
                        return (
                            item !== selectedCurrencyTo && (
                                <MenuItem key={`b${item}`} value={item} className={classes.select} aria-label={item}>
                                    {item}
                                </MenuItem>
                            )
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabelB} id="currency-label-to" className={classes.label} aria-label="select a currency - currency to">
                    CURRENCY TO
                </InputLabel>
                <Select labelId="currency-label-to" id="currency-to" labelWidth={labelWidthB} value={selectedCurrencyTo} onChange={handleCurrencyToChange} name="to-ident" inputProps={{ name: 'currencyTo', id: 'currency-label-to' }}>
                    <Typography component="h3" variant="overline" className={classes.headline} aria-label="group of fiat currencies">
                        Fiat
                    </Typography>
                    {fiatCurrencies.map(item => {
                        return (
                            item !== selectedCurrencyFrom && (
                                <MenuItem key={`${item}`} value={item} className={classes.select} aria-label={item}>
                                    {item}
                                </MenuItem>
                            )
                        );
                    })}
                    <Typography component="h3" variant="overline" className={classes.headline} aria-label="group of crypto currencies">
                        Crypto
                    </Typography>
                    {cryptoCurrencies.map(item => {
                        return (
                            item !== selectedCurrencyFrom && (
                                <MenuItem key={`a${item}`} value={item} className={classes.select} aria-label={item}>
                                    {item}
                                </MenuItem>
                            )
                        );
                    })}
                </Select>
            </FormControl>
            <div className={classes.errorMessage}>{errorMessage}</div>
        </Fragment>
    );
}
