import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../contexts/SettingsContext';
import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

const CryptoCurrency = () => {
    const { selectedCurrencyFrom, selectedCurrencyTo } = useContext(SettingsContext);

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2),
        },
    }));

    const classes = useStyles();
    return (
        <Typography variant="overline" className={classes.root}>
            {selectedCurrencyFrom}/{selectedCurrencyTo}
        </Typography>
    );
};

export default CryptoCurrency;
