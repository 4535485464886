import React, { useEffect, useContext } from 'react';
import CryptoWeather from './pages/CryptoWeather';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { SettingsContext } from './contexts/SettingsContext';
import { light, dark } from './theme/default';
import mainConfig from './config/mainConfig';
import ReactGA from 'react-ga';
ReactGA.initialize(mainConfig['ga-tracking-id']);
ReactGA.pageview(window.location.pathname + window.location.search);

export default () => {
    const { selectedTheme } = useContext(SettingsContext);
    const [selectedTemplateTheme, setTemplateTheme] = React.useState(false);
    document.body.className = selectedTheme;

    const handleThemeChangeGlobal = event => {
        if (event === 'light') {
            setTemplateTheme(light);
        } else {
            setTemplateTheme(dark);
        }
    };

    useEffect(() => {
        handleThemeChangeGlobal(selectedTheme);
    });

    const pub = createMuiTheme(selectedTemplateTheme);

    return (
        <ThemeProvider theme={pub}>
            <div>
                <CssBaseline />
                <CryptoWeather />
            </div>
        </ThemeProvider>
    );
};
