import React, { useContext } from 'react';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../contexts/SettingsContext';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function ThemeSelect() {
    const classes = useStyles();
    const { selectedTheme, handleThemeChange } = useContext(SettingsContext);

    return (
        <form className={classes.root} autoComplete="off">
            <FormControl variant="outlined" className={classes.formControl}>
                <FormControlLabel control={<Switch checked={selectedTheme === 'light' ? false : true} onChange={handleThemeChange(selectedTheme === 'light' ? 'dark' : 'light')} value={selectedTheme} />} label="DARK" />
            </FormControl>
        </form>
    );
}
