export const light = {
    themePublication: 'default',
    themeName: 'default',
    spacing: 8,
    palette: {
        primary: {
            main: 'rgba(1,36,35,.65)',
        },
        text: {
            primary: '#012423',
        },
        secondary: {
            light: 'rgba(170, 153, 119, 0.2)',
            main: 'rgba(1,36,35,.65)',
        },
        background: {
            default: 'rgb(249, 255, 255)',
        },
        altColor: '#ffffff',
        wrapperBackground: '#ffffff',
        menuBackgroundColor: 'rgb(249, 255, 255)',
        type: 'light',
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: 'rgba(232,251,251,1)',
            },
        },
    },
    typography: {
        fontFamily: 'Fira Sans, Arial',
    },
};

export const dark = {
    ...light,
    themeName: 'default(dark)',
    palette: {
        ...light.palette,
        primary: {
            main: '#e8fcfb',
        },
        secondary: {
            light: 'rgba(170, 153, 119, 0.2)',
            main: '#e8fcfb',
        },
        text: {
            primary: '#e8fcfb',
        },
        background: {
            default: 'rgb(249, 255, 255)',
        },
        menuBackgroundColor: '#003838',
        wrapperBackground: '#000404',
        altColor: 'rgba(1,36,36,0.5)',
        type: 'dark',
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: '#012423',
            },
        },
    },
};
