import React, { useContext } from 'react';
import { unixTimeConvert } from '../helpers/UnixTimeConverter';
import { useTheme } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import Exporting from 'highcharts/modules/exporting';
import { SettingsContext } from '../contexts/SettingsContext';

// Initialize exporting module.
// Exporting(Highcharts);

const Chart = () => {
    const volume = [];
    const { selectedCurrencyFrom, selectedCurrencyTo, selectedLoadHistoData, selectedTimeRange } = useContext(SettingsContext);
    const jsonData = selectedLoadHistoData && JSON.parse(selectedLoadHistoData);
    const theme = useTheme();

    try {
        for (const [index, item] of jsonData.entries()) {
            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes();

            if (selectedTimeRange === 'HOUR' && index > jsonData.length - 60) {
                volume.push([unixTimeConvert(item.time), item.close]);
            }
            if (selectedTimeRange === 'DAY' && index > jsonData.length - Math.round(hours * 60 + minutes)) {
                volume.push([unixTimeConvert(item.time), item.close]);
            }
            if (selectedTimeRange === '24HOUR') {
                volume.push([unixTimeConvert(item.time), item.close]);
            }
        }
    } catch (err) {
        console.log(err.message);
    }

    const chartConfig = {
        title: {
            text: `${selectedCurrencyFrom}/${selectedCurrencyTo}`,
            style: {
                color: '#ffffff',
            },
        },
        credits: {
            enabled: false,
        },
        rangeSelector: {
            enabled: true,
            selected: 1,
        },
        legend: {
            enabled: true,
            symbolWidth: 8,
        },
        chart: {
            backgroundColor: theme.palette.type === 'dark' ? 'transparent' : '#ffffff',
        },
        plotOptions: {
            series: {
                showInLegend: true,
                fillOpacity: 0.5,
            },
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(232, 251, 251, 1)'],
                        [1, 'rgba(232,251,251, 0.15)'],
                    ],
                },
                marker: {
                    radius: 5,
                },
                lineWidth: 1,
                lineColor: '#012423',
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                threshold: null,
            },
        },
        navigator: {
            handles: {
                backgroundColor: '#e8fcfb',
                borderColor: '#012423',
            },
            maskFill: 'rgba(232, 251, 251, 0.75)',
            maskInside: true,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%e. %b',
            },
            title: {
                text: 'Date',
            },
            labels: {
                style: {
                    color: theme.palette.type === 'dark' ? '#ffffff' : '#012423',
                },
            },
        },
        yAxis: {
            gridLineColor: theme.palette.type === 'dark' ? '#012423' : 'rgba(1, 36, 35, 0.65)',
            title: {
                text: 'Price',
            },
            labels: {
                style: {
                    color: theme.palette.type === 'dark' ? '#ffffff' : '#012423',
                },
            },
        },
        series: [
            {
                type: 'area',
                name: `price`,
                data: volume,
                color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.65)' : 'rgba(1, 36, 35, 0.65)',
                tooltip: {
                    valueDecimals: 8,
                },
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={chartConfig} />;
};

export default Chart;
