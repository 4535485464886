import React, { useContext, useState, useEffect } from 'react';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { SettingsContext } from '../contexts/SettingsContext';

const CryptoCurrencyMeta = ({ text, include, suffix, primaryVariant, secondaryVariant, fixed, primaryAlign, secondaryAlign }) => {
    const { selectedCurrencyFrom, selectedCurrencyTo, selectedPriceFull } = useContext(SettingsContext);
    const keysFrom = Object.keys(JSON.parse(selectedPriceFull))[0];
    const [includes, setIncludes] = useState(0);

    useEffect(() => {
        if (selectedCurrencyFrom === keysFrom) {
            const keysTo = Object.keys(JSON.parse(selectedPriceFull)[selectedCurrencyFrom])[0];
            if (selectedCurrencyTo === keysTo) {
                setIncludes(JSON.parse(selectedPriceFull)[selectedCurrencyFrom][selectedCurrencyTo][include].toFixed(fixed ? fixed : 5));
            }
        }
    }, [include, fixed, keysFrom, selectedCurrencyFrom, selectedCurrencyTo, selectedPriceFull]);

    const label = text;

    let suffixes = 'selectedCurrencyTo';
    if (suffix === 'FROM') {
        suffixes = selectedCurrencyFrom;
    }
    if (suffix === 'TO') {
        suffixes = selectedCurrencyTo;
    }

    if (suffix === 'PCT') {
        suffixes = '%';
    }

    return (
        <ListItem component="div" className="removed-padding">
            <ListItemText
                primary={
                    label ? (
                        <Typography component="div" variant={primaryVariant ? primaryVariant : 'subtitle1'} className={label === 'CHANGE 24HOUR' ? '#fff' : 'primary'} align={primaryAlign ? primaryAlign : 'inherit'}>
                            {label}
                        </Typography>
                    ) : (
                        ''
                    )
                }
                secondary={
                    <Typography component="div" variant={secondaryVariant ? secondaryVariant : 'subtitle2'} align={secondaryAlign ? secondaryAlign : 'inherit'}>
                        {includes ? includes : 0} {suffixes ? suffixes : ''}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default CryptoCurrencyMeta;
