import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(2),
    },
    headlineSpace: {
        padding: theme.spacing(1, 2),
    },
    tippbot: {
        transform: 'scale(0.7)',
        transformOrigin: 'top left',
        marginLeft: theme.spacing(2),
    },
}));
const XrpTipbot = () => {
    const classes = useStyles();
    // Render

    let loadScript = function(src) {
        const tag = document.createElement('script');
        tag.async = true;
        tag.src = src;
        document.getElementById('tippbotscript').appendChild(tag);
    };

    useEffect(() => {
        loadScript('//www.xrptipbot.com/static/donate/tipper.js');
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Typography variant="overline" className={classes.headlineSpace}>
                Support the developer
            </Typography>
            <Typography component={'div'} className={classes.tippbot}>
                {/* eslint-disable-next-line */}
                <a amount="0.75" size="275" to="scurrilus" network="twitter" href="https://www.xrptipbot.com" target="_blank" rel="noopener noreferrer" stylesheet="https://www.untermstrichzahlich.de/xrptipbot/styles.css"></a>
            </Typography>
        </Fragment>
    );
};

export default XrpTipbot;
