// unix Time Converter
export const unixTimeConvert = unixTime => {
    const dt = new Date(unixTime * 1000);
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const day = dt.getDate();
    const hr = dt.getHours();
    const m = '0' + dt.getMinutes();
    const s = '0' + dt.getSeconds();
    return Date.UTC(year, month, day, hr, m, s);
};
