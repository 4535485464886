import React, { useContext } from 'react';
import { Grid, Container, Typography, Paper, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CryptoCurrency from '../components/CryptoCurrency';
import CryptoCurrencyMeta from '../components/CryptoCurrencyMeta';
import CryptoClock from '../components/CryptoClock';
import Logo from '../components/Logo';
import Settings from '../components/Settings';
import Charts from '../components/Charts';
// import Alien from '../components/Alien';
import Moon from '../components/Moon';
import XrpTipbot from '../components/XrpTipbot';
import PageTracking from '../components/PageTracking';
import CookieDisclaimer from '../components/CookieDisclaimer';
import { SettingsContext } from '../contexts/SettingsContext';
import { TwitterDMButton } from 'react-twitter-embed';
import CryptoCurrencyNews from '../components/CryptoCurrencyNews';
import FullscreenDialog from '../components/FullscreenDialog';

const CryptoWeather = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const cookieDisclaimer = localStorage.getItem('cookieDisclaimer');

    // const selectedTimeRange = '24HOUR';
    const { selectedTimeRange } = useContext(SettingsContext);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            background: theme.palette.wrapperBackground,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        paper: {
            height: '100%',
            overflow: 'hidden',
            background: theme.palette.paper,
        },
        spaceTop: {
            marginTop: theme.spacing(1),
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        sky: {
            overflow: 'hidden',
            height: '100%',
            position: 'relative',
            color: '#ffffff',
        },
        fixHeight: {
            height: '100%',
            minHeight: '300px',
        },
        fixPosition: {
            position: 'absolute',
            bottom: 0,
        },
        special: {
            background: theme.palette.type === 'dark' ? '#435c5a' : '#ffffff',
            textShadow: '1px 1px rgba(0, 0, 0, .15)',
        },
        space: {
            marginTop: theme.spacing(0.5),
        },
        altColor: {
            height: '100%',
            overflow: 'hidden',
            background: theme.palette.altColor,
        },
        twitterMessage: {
            padding: theme.spacing(1, 2),
        },
        footer: {
            maxHeight: 75,
        },
        fullscreenMode: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            zIndex: 5,
            padding: '0 !important',
        },
    }));

    const classes = useStyles();

    return (
        <Container maxWidth="lg" className={classes.root + ' space'}>
            {cookieDisclaimer ? <PageTracking /> : <CookieDisclaimer />}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper elevation={5} className={(classes.paper, classes.spaceTop)}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Logo height={50} spaceTop={6} />
                            </Grid>
                            <Grid item xs={6}>
                                <Box align="right" className={classes.space}>
                                    <Typography variant="overline" align="center">
                                        <CryptoCurrency />
                                    </Typography>
                                    <Typography variant="overline" align="center">
                                        <Settings align="center" />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.altColor}>
                        <Grid container>
                            <CryptoClock />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={5} className={classes.sky}>
                        <Grid container className={classes.fixHeight}>
                            <Grid item xs={12} className="space">
                                {/* <Alien /> */}
                                <FullscreenDialog />
                                <Typography variant="overline" align="center">
                                    <CryptoCurrency />
                                </Typography>
                                <Typography component="h2" variant="h1" align="center">
                                    <Moon include={'CHANGEPCT' + selectedTimeRange} />
                                </Typography>
                                <Grid container className={classes.fixPosition}>
                                    <Grid item xs={6}>
                                        <CryptoCurrencyMeta text={`CHANGE ${selectedTimeRange}`} include={'CHANGE' + selectedTimeRange} suffix={'TO'} fixed={5} secondaryVariant={matchesSm ? 'h5' : 'h6'} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box align="right">
                                            <CryptoCurrencyMeta include={'CHANGEPCT' + selectedTimeRange} suffix={'PCT'} secondaryVariant={matches ? 'h3' : 'h4'} secondaryAlign="right" fixed={3} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={5} className={classes.altColor}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CryptoCurrencyMeta text="PRICE" include="PRICE" suffix="TO" primaryVariant="button" secondaryVariant={matches ? 'h3' : 'h4'} />
                            </Grid>
                            <Grid item xs={6} sm={12} md={6}>
                                <CryptoCurrencyMeta text={`LOW ${selectedTimeRange}`} include={'LOW' + selectedTimeRange} suffix={'TO'} secondaryVariant={matchesSm ? 'h5' : 'h6'} />
                            </Grid>
                            <Grid item xs={6} sm={12} md={6}>
                                <CryptoCurrencyMeta text={`HIGH ${selectedTimeRange}`} include={'HIGH' + selectedTimeRange} suffix={'TO'} secondaryVariant={matchesSm ? 'h5' : 'h6'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CryptoCurrencyMeta text={`VOLUME ${selectedTimeRange}`} include={'VOLUME' + selectedTimeRange} suffix={'TO'} fixed={2} secondaryVariant={matchesSm ? 'h5' : 'h6'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CryptoCurrencyMeta text="MKTCAP" include={'MKTCAP'} suffix={'TO'} fixed={2} secondaryVariant={matchesSm ? 'h5' : 'h6'} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.altColor}>
                        <Charts />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.altColor}>
                        <CryptoCurrencyNews />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={5} className={classes.paper}>
                        <Grid container className={classes.footer}>
                            <Grid item xs={7}>
                                <XrpTipbot />
                            </Grid>
                            <Grid item xs={5}>
                                <Box align="right" className={classes.space}>
                                    <div className={classes.twitterMessage}>
                                        <TwitterDMButton className={classes.twitterMessage} id={453867892} options={{ text: 'CRYPTO-MOON: ' }} />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CryptoWeather;
