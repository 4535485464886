import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import { SettingsContext } from '../contexts/SettingsContext';
import Moment from 'react-moment';
import 'moment-timezone';
import { eventTracking } from '../helpers/Tracking';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AdSense from 'react-adsense';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        width: '100%',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            float: 'none',
            padding: theme.spacing(2, 2, 0, 2),
        },
        [theme.breakpoints.up('sm')]: {
            width: 135,
            padding: theme.spacing(2, 0, 0, 2),
            float: 'left',
        },
    },
    dialogTitle: {
        textTransform: 'uppercase',
    },
}));

const CryptoCurrencyNews = () => {
    const { selectedNewsData } = useContext(SettingsContext);
    const data = JSON.parse(selectedNewsData);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [targetSource, setTargetSource] = React.useState();
    const [targetUrl, setTargetUrl] = React.useState();
    const [scroll, setScroll] = React.useState('paper');

    // Set the output format for every react-moment instance.
    Moment.globalFormat = 'MMMM DD YYYY hh:mm a';

    const handleClickOpen = (scrollType, category, action, label, source) => () => {
        // console.log('handle click open', scrollType, category, action, label);
        setTargetSource(source);
        setTargetUrl(label);
        setOpen(true);
        setScroll(scrollType);
        eventTracking(category, action, label);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Fragment>
            <List>
                {selectedNewsData &&
                    data.map(item => (
                        <ListItem key={item.id}>
                            <Card className={classes.card}>
                                <CardActionArea onClick={handleClickOpen('paper', 'Link', 'click', item.url, item.source)}>
                                    <CardMedia className={classes.cover} component="img" image={item.imageurl} title="Live from space album cover" />
                                    <div className={classes.details}>
                                        <CardContent className={classes.content}>
                                            <Typography variant="overline" color="textSecondary">
                                                {item.source} - <Moment unix>{item.published_on}</Moment>
                                            </Typography>
                                            <Typography component="h3" variant="h5">
                                                {item.title}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {item.body.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode))} ...
                                            </Typography>
                                        </CardContent>
                                    </div>
                                </CardActionArea>
                            </Card>
                        </ListItem>
                    ))}
            </List>
            <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
                    You are now leaving the Crypto Moon.
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <AdSense.Google client="ca-pub-5044999046375301" slot="9564137208" />
                        <Button onClick={handleClose} color="primary" component={Link} href={targetUrl} underline="none" target="_blank">
                            {`Please click here and visit - ${targetSource}`}
                        </Button>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default CryptoCurrencyNews;
