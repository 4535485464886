import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import CryptoCurrency from './CryptoCurrency';
import CryptoCurrencyMeta from './CryptoCurrencyMeta';
import Moon from './Moon';
import { SettingsContext } from '../contexts/SettingsContext';
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fullscreenButton: {
        right: theme.spacing(1),
        position: 'absolute',
        top: theme.spacing(1),
        // background: '#012423',
        color: '#ffffff',
        zIndex: 1000,
    },
    fixHeight: {
        height: '100vh',
        overflow: 'hidden',
    },
    fixPositionBottom: {
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
        },

        '& .MuiTypography-root': {
            color: 'rgba(232,251,251,1)',
        },
    },
    fixPositionTop: {
        position: 'absolute',
        padding: theme.spacing(2, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4),
        },

        '& .MuiTypography-root': {
            color: 'rgba(232,251,251,1)',
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const theme = useTheme();
    const classes = useStyles();
    const { selectedTimeRange } = useContext(SettingsContext);
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matches = useMediaQuery('(min-width:668px)');
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

        ReactGA.event({
            category: 'Display',
            action: 'Click',
            label: `Fullscreen open`,
        });
    };

    const handleClose = () => {
        setOpen(false);

        ReactGA.event({
            category: 'Display',
            action: 'Click',
            label: `Fullscreen close`,
        });
    };

    return (
        <div>
            <ToggleButton className={classes.fullscreenButton} value="check" onClick={handleClickOpen} aria-label="open fullscreen">
                <Fullscreen />
            </ToggleButton>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <ToggleButton className={classes.fullscreenButton} value="uncheck" onClick={handleClose} aria-label="close fullscreen">
                    <FullscreenExitIcon />
                </ToggleButton>
                <Grid container className={classes.fixHeight}>
                    <Grid item xs={12} className="space">
                        {/* <Alien /> */}
                        <Typography component="" variant="overline" align="center">
                            <CryptoCurrency />
                        </Typography>
                        <Grid container className={classes.fixPositionTop}>
                            <Grid item xs={6}>
                                <CryptoCurrencyMeta text={`CHANGE ${selectedTimeRange}`} include={'CHANGE' + selectedTimeRange} suffix={'TO'} fixed={matches ? 4 : 5} secondaryVariant={matches ? 'h4' : matchesSm ? 'h5' : 'h6'} secondaryAlign="left" />
                            </Grid>
                            <Grid item xs={6}>
                                <Box align="right">
                                    <CryptoCurrencyMeta text={` `} include={'CHANGEPCT' + selectedTimeRange} suffix={'PCT'} secondaryVariant={matches ? 'h2' : matchesSm ? 'h3' : 'h4'} secondaryAlign="right" fixed={3} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography component="h2" variant="h1" align="center">
                            <Moon include={'CHANGEPCT' + selectedTimeRange} fullscreen={true} />
                        </Typography>
                        <Grid container className={classes.fixPositionBottom}>
                            <Grid item xs={12}>
                                <CryptoCurrencyMeta text="PRICE" include="PRICE" suffix="TO" secondaryVariant={matches ? 'h4' : matchesSm ? 'h5' : 'h5'} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CryptoCurrencyMeta text={`VOLUME ${selectedTimeRange}`} include={'VOLUME' + selectedTimeRange} suffix={'TO'} fixed={2} secondaryVariant={matches ? 'h4' : matchesSm ? 'h5' : 'h6'} primaryAlign="left" secondaryAlign="left" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CryptoCurrencyMeta text="MKTCAP" include={'MKTCAP'} suffix={'TO'} fixed={2} secondaryVariant={matches ? 'h4' : matchesSm ? 'h5' : 'h6'} primaryAlign={matchesSm ? 'right' : 'left'} secondaryAlign={matchesSm ? 'right' : 'left'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
