import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LazyLoad from 'react-lazyload';
import { Typography } from '@material-ui/core';

const Logo = ({ height, spaceTop, className }) => {
    const theme = useTheme();
    const image = `/images/${theme.themePublication}/cryptomoon-logo-${theme.palette.type}.svg`;

    // Styles
    let lazyHeight = 200;

    let imgStyles = {
        height: 'auto',
    };

    if (height) {
        imgStyles = {
            height: parseInt(height),
            paddingTop: parseInt(spaceTop),
        };
        lazyHeight = parseInt(height);
    }

    const useStyles = makeStyles({
        img: {
            height: imgStyles.height,
            paddingTop: imgStyles.paddingTop,
            maxWidth: '100%',
        },
        headline: {
            fontSize: 0,
            margin: 0,
            padding: 0,
        },
    });

    const classes = useStyles();
    const classesCompilation = className ? `${classes.img} ${className}` : `${classes.img}`;

    // Render
    return (
        <Fragment>
            <LazyLoad height={lazyHeight}>
                <img className={classesCompilation} src={image} alt={`logo-${theme.palette.type}`} />
            </LazyLoad>
            <Typography component="h1" className={classes.headline}>
                <span>Cryptomoon - Crypto Moon</span>
            </Typography>
        </Fragment>
    );
};

export default Logo;
