import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@material-ui/core/';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const CookieDisclaimer = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('cookieDisclaimer', 'accepted');
    };

    useEffect(() => {
        handleClickOpen();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" disableBackdropClick disableEscapeKeyDown>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    The European data protection law requires, that you have to accept the following before you use this website:
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>This website use cookies and other technologies to customize your experience and perform analytics. By clicking “I agree” below, you consent to the use of cookies and data gathered from of this platform.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                    </Button> */}
                    <Button onClick={handleClose} color="primary">
                        I agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CookieDisclaimer;
