import { useEffect } from 'react';
import { eventTracking } from '../helpers/Tracking';

const PageTracking = () => {
    // Tracking
    useEffect(() => {
        eventTracking('Currencies', 'Range Selected on page load', localStorage.getItem('timeRange'));
        eventTracking('Currencies', 'Currency From Selected on page load', localStorage.getItem('currencyFrom'));
        eventTracking('Currencies', 'Currency To Selected on page load', localStorage.getItem('currencyTo'));
        // eslint-disable-next-line
    }, []);

    return false;
};

export default PageTracking;
