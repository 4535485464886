const cc = require('cryptocompare');
cc.setApiKey('f45b448ebe0da351bc7f824486ad9d66dd50764feabd0f7dfc1081ae780305d7');

export const ccPriceFull = parameter => {
    return cc
        .priceFull(parameter[0], parameter[1])
        .then(prices => {
            return prices;
        })
        .catch(console.error);
};

export const ccHistoDay = parameter => {
    return cc
        .histoDay(parameter[0], parameter[1])
        .then(prices => {
            // console.log('ccPriceHistorical:', prices);
            return prices;
        })
        .catch(console.error);
};

export const ccHistoHour = parameter => {
    console.log('parameter', parameter);
    return cc
        .histoHour(parameter[0], parameter[1])
        .then(prices => {
            // console.log('ccPriceHistorical:', prices);
            return prices;
        })
        .catch(console.error);
};

export const ccHistoMinute = parameter => {
    return cc
        .histoMinute(parameter[0], parameter[1])
        .then(prices => {
            // console.log('ccPriceHistorical:', prices);
            return prices;
        })
        .catch(console.error);
};

export const ccNews = parameter => {
    return cc
        .newsList('EN', { categories: [parameter[0], parameter[1]] })
        .then(newsList => {
            console.log(newsList);
            return newsList;
        })
        .catch(console.error);
};
