import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../contexts/SettingsContext';

const Moon = ({ include, fullscreen }) => {
    const { selectedCurrencyFrom, selectedCurrencyTo, selectedPriceFull } = useContext(SettingsContext);
    const [includes, setIncludes] = useState(0);
    const data = JSON.parse(selectedPriceFull);
    const keysFrom = Object.keys(data)[0];
    const [iconClass, setIconClass] = useState('wi-moon-new');
    const [iconColor, setIconColor] = useState('');
    const colorUp = 'rgba(0, 255, 0, 0.1)';
    const colorDown = 'rgba(255, 0, 0, 0.2)';

    const useStyles = makeStyles(theme => ({
        colorDown: {
            '&:before': {
                color: colorDown,
                textShadow: `0 0 30px ${colorDown}`,
            },
            '&:after': {
                color: colorDown,
                textShadow: `0 0 50px ${colorDown}`,
            },
        },
        colorUp: {
            '&:before': {
                color: colorUp,
                textShadow: `0 0 30px ${colorUp}`,
            },
            '&:after': {
                color: colorUp,
                textShadow: `0 0 50px ${colorUp}`,
            },
        },
    }));

    useEffect(() => {
        const dataPath = includes ? includes : 0;

        if (selectedCurrencyFrom === keysFrom) {
            const keysTo = Object.keys(data[selectedCurrencyFrom])[0];
            if (selectedCurrencyTo === keysTo) {
                setIncludes(data[selectedCurrencyFrom][selectedCurrencyTo][include]);
            }
        }

        if (dataPath < 0) {
            setIconColor('colorDown');
            setIconClass('wi-moon-new');
        }

        if (dataPath >= 0) {
            setIconColor('colorUp');
            setIconClass('wi-moon-new');
        }

        // Up
        if (dataPath > 0.25) {
            setIconClass('wi-moon-waxing-cresent-1');
        }

        if (dataPath > 0.5) {
            setIconClass('wi-moon-waxing-cresent-2');
        }

        if (dataPath > 0.75) {
            setIconClass('wi-moon-waxing-cresent-3');
        }

        if (dataPath > 1) {
            setIconClass('wi-moon-waxing-cresent-4');
        }

        if (dataPath > 1.25) {
            setIconClass('wi-moon-waxing-cresent-5');
        }

        if (dataPath > 1.5) {
            setIconClass('wi-moon-waxing-cresent-6');
        }

        if (dataPath > 2) {
            setIconClass('wi-moon-first-quarter');
        }

        if (dataPath > 2.25) {
            setIconClass('wi-moon-waxing-gibbous-1');
        }

        if (dataPath > 2.5) {
            setIconClass('wi-moon-waxing-gibbous-2');
        }

        if (dataPath > 2.75) {
            setIconClass('wi-moon-waxing-gibbous-3');
        }

        if (dataPath > 3) {
            setIconClass('wi-moon-waxing-gibbous-4');
        }

        if (dataPath > 3.25) {
            setIconClass('wi-moon-waxing-gibbous-5');
        }

        if (dataPath > 3.5) {
            setIconClass('wi-moon-waxing-gibbous-6');
        }

        // down
        if (dataPath < -0.25) {
            setIconClass('wi-moon-waning-crescent-6');
        }

        if (dataPath < -0.5) {
            setIconClass('wi-moon-waning-crescent-5');
        }

        if (dataPath < -0.75) {
            setIconClass('wi-moon-waning-crescent-4');
        }

        if (dataPath < -1) {
            setIconClass('wi-moon-waning-crescent-3');
        }

        if (dataPath < -1.25) {
            setIconClass('wi-moon-waning-crescent-2');
        }

        if (dataPath < -1.5) {
            setIconClass('wi-moon-waning-crescent-1');
        }

        if (dataPath < -2) {
            setIconClass('wi-moon-3rd-quarter');
        }

        if (dataPath < -2.25) {
            setIconClass('wi-moon-waning-gibbous-6');
        }

        if (dataPath < -2.5) {
            setIconClass('wi-moon-waning-gibbous-5');
        }

        if (dataPath < -2.75) {
            setIconClass('wi-moon-waning-gibbous-4');
        }

        if (dataPath < -3) {
            setIconClass('wi-moon-waning-gibbous-3');
        }

        if (dataPath < -3.25) {
            setIconClass('wi-moon-waning-gibbous-2');
        }

        if (dataPath < -3.5) {
            setIconClass('wi-moon-waning-gibbous-1');
        }

        // Full
        if (dataPath > 4 || dataPath < -4) {
            setIconClass('wi-moon-full');
        }
    }, [selectedCurrencyFrom, keysFrom, data, selectedCurrencyTo, includes, include]);

    const classes = useStyles();

    return (
        <div className={`moon-shadow ${fullscreen ? 'fullscreen' : ''}`}>
            <i className={`wi ${iconClass} ${classes[iconColor]} moon`}></i>
        </div>
    );
};

export default Moon;
