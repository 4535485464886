import ReactGA from 'react-ga';
import mainConfig from '../config/mainConfig';
ReactGA.initialize(mainConfig['ga-tracking-id']);

export const eventTracking = (category, action, label) => {
    console.log('Tracking', category, action, label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};
