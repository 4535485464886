import React, { createContext, useState, useEffect } from 'react';
import mainConfig from '../config/mainConfig';
import { ccPriceFull, /*ccHistoHour, ccHistoDay, */ ccHistoMinute, ccNews } from '../helpers/CryptoCompare';
import ReactGA from 'react-ga';
ReactGA.initialize(mainConfig['ga-tracking-id']);

export const SettingsContext = createContext();

const SettingsContextProvider = props => {
    // Theme
    const [selectedTheme, setTheme] = useState(localStorage.getItem('theme') || mainConfig.theme);
    const handleThemeChange = name => event => {
        localStorage.setItem('theme', name);
        setTheme(name);

        ReactGA.event({
            category: 'Theme',
            action: 'Theme Select',
            label: name,
        });
    };

    // Time Range
    const [selectedTimeRange, setTimeRange] = useState(localStorage.getItem('timeRange') || mainConfig.timeRange);
    const handleTimeRangeSelectChange = event => {
        localStorage.setItem('timeRange', event.target.value);
        setTimeRange(event.target.value);

        // Tracking
        ReactGA.event({
            category: 'Currencies',
            action: 'Range Select',
            label: event.target.value,
        });
    };

    // Currency From
    const [selectedCurrencyFrom, setCurrencyFrom] = useState(localStorage.getItem('currencyFrom') || mainConfig.currencyFrom);
    const handleCurrencyFromChange = event => {
        localStorage.setItem('currencyFrom', event.target.value);
        setCurrencyFrom(event.target.value);

        // Tracking
        ReactGA.event({
            category: 'Currencies',
            action: 'Currency From Select',
            label: `from: ${event.target.value}`,
        });
    };

    const [count, setCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    // Currency To
    const [selectedCurrencyTo, setCurrencyTo] = useState(localStorage.getItem('currencyTo') || mainConfig.currencyTo);
    const handleCurrencyToChange = event => {
        localStorage.setItem('currencyTo', event.target.value);
        setCurrencyTo(event.target.value);

        // Tracking
        ReactGA.event({
            category: 'Currencies',
            action: 'Currency To Select',
            label: `to: ${event.target.value}`,
        });
    };

    // Full Price
    const [selectedPriceFull, setSelectedPriceFull] = useState(localStorage.getItem('priceFull') || false);
    const handleLoadPriceFull = () => {
        ccPriceFull([selectedCurrencyFrom, selectedCurrencyTo])
            .then(data => {
                console.log('ccPriceFull', data);
                if (data !== undefined) {
                    setSelectedPriceFull(data ? JSON.stringify(data) : {});
                    localStorage.setItem('priceFull', JSON.stringify(data));
                } else {
                    setErrorMessage('No Data for this pair of currencies.');
                }
            })
            .catch(console.error);
    };

    // Histo Data Price
    const [selectedLoadHistoData, setSelectedLoadHistoData] = useState(localStorage.getItem('loadHistoData') || false);
    const handleLoadHistoData = () => {
        try {
            ccHistoMinute([selectedCurrencyFrom, selectedCurrencyTo])
                .then(data => {
                    // console.log('ccHistoMinute', data);
                    if (data !== undefined) {
                        setSelectedLoadHistoData(data ? JSON.stringify(data) : {});
                        localStorage.setItem('loadHistoData', JSON.stringify(data));
                        setErrorMessage(false);
                    } else {
                        setErrorMessage('No Data for this pair of currencies.');
                    }
                    return false;
                })
                .catch(console.error);
        } catch (err) {
            console.log('It was not possible to load Data', err);
        }
    };

    // News
    const [selectedNewsData, setSelectedNewsData] = useState(localStorage.getItem('news') || false);
    const handleLoadNews = () => {
        ccNews([selectedCurrencyFrom, selectedCurrencyTo])
            .then(data => {
                // console.log('ccNews', data);
                if (data !== undefined) {
                    setSelectedNewsData(data ? JSON.stringify(data) : {});
                    localStorage.setItem('news', JSON.stringify(data));
                } else {
                    setErrorMessage('No Data for this pair of currencies.');
                }
            })
            .catch(console.error);
    };

    useEffect(() => {
        handleLoadNews();
        handleLoadPriceFull();
        handleLoadHistoData();
        // eslint-disable-next-line
    }, [selectedCurrencyFrom, selectedCurrencyTo, count]);

    const timer = mainConfig['dataRefreshTime'];
    let countUp = count;

    useEffect(() => {
        setInterval(() => setCount(countUp++), timer);
        // eslint-disable-next-line
    }, []);

    return (
        <SettingsContext.Provider
            value={{
                handleThemeChange,
                handleTimeRangeSelectChange,
                handleCurrencyFromChange,
                handleCurrencyToChange,
                selectedTheme,
                selectedTimeRange,
                selectedCurrencyFrom,
                selectedCurrencyTo,
                selectedPriceFull,
                selectedLoadHistoData,
                selectedNewsData,
                errorMessage,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    );
};

export default SettingsContextProvider;
