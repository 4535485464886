import React, { Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, IconButton, Typography, useMediaQuery } from '@material-ui/core';

import ThemeSelect from './SettingsThemeSelect';
import CurrencySelect from './SettingsCurrencySelect';
import TimeRangeSelect from './SettingsTimeRangeSelect';
import LegalNotice from './LegalNotice';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    settings: {
        marginTop: theme.spacing(1),
    },
    elementSpace: {
        padding: theme.spacing(2),
    },
    headline: {
        padding: theme.spacing(0, 2),
    },
}));

export default function Settings() {
    const theme = useTheme();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
        setBurgerState(open ? <CloseIcon /> : <MenuIcon />);
    };

    // New
    let menuDirection = 'right';

    if (matches) {
        menuDirection = 'left';
    }
    // define States
    const [burgerState, setBurgerState] = useState(<MenuIcon />);
    const [state, setState] = React.useState({
        right: false,
        left: false,
    });

    return (
        <Fragment>
            <IconButton edge="start" aria-label="open drawer" onClick={toggleDrawer(menuDirection, true)}>
                {burgerState}
            </IconButton>

            <Drawer className={classes.root} anchor={menuDirection} open={menuDirection === 'right' ? state.right : state.left} onClose={toggleDrawer(menuDirection, false)}>
                <ThemeSelect />
                <Typography variant="overline" className={classes.headline}>
                    Currency select
                </Typography>
                <CurrencySelect />
                <Typography variant="overline" className={classes.headline}>
                    Time range
                </Typography>
                <TimeRangeSelect />
                <Typography variant="overline" className={[classes.headline, classes.settings]}>
                    More
                </Typography>
                <div className={classes.elementSpace}>
                    <LegalNotice />
                </div>
            </Drawer>
        </Fragment>
    );
}
